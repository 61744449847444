<template lang="pug">
div(class='SliderImages')
	UiCarousel(
		class='SliderImages--Carousel',
		:class='{ ArrowAside: asideArrow }',
		:perView='greaterThan("mobileWide") && lessThan("desktop") ? 2 : 1',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(v-for='image in images', :key='image.id', v-bind='carouselItemProps')
					div(class='SliderImages--Carousel--Item')
						UiImage(:image='image', width='500px', lazy)

	div(
		v-if='greaterThan("tablet") || (asideArrow && greaterThan("mobile"))',
		class='SliderImages--CarouselNavigation',
		:class='{ ArrowAside: asideArrow }'
	)
		UiButton(
			class='SliderImages--CarouselNavigation--Button',
			:class='{ Disabled: activeSlide === 1 }',
			variant='secondary',
			rounded,
			square,
			paddet,
			size='xl',
			iconLeft='system/arrow-left',
			iconLeftSize='md',
			@click='slidePrev'
		)
		UiButton(
			class='SliderImages--CarouselNavigation--Button',
			:class='{ Disabled: isEndSlide }',
			variant='secondary',
			rounded,
			square,
			size='xl',
			iconRight='system/arrow-right',
			iconRightSize='md',
			@click='slideNext'
		)
</template>

<script lang="ts" setup>
import { Image } from '~/models/image';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);
const activeSlide = computed(() => slider.value?.activeSlide || 1);
const isEndSlide = computed(() => slider.value?.isEndSlide || false);
const slideNext = () => slider.value?.slideNext();
const slidePrev = () => slider.value?.slidePrev();

/**
 * Define props
 */
const { images = [], asideArrow = false } = defineProps<{
	images: Image.Model[];
	asideArrow?: boolean;
}>();

/**
 * Define hooks
 */
const { lessThan, greaterThan } = usePlatform();
</script>

<style lang="less" scoped>
.SliderImages {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; center);

	&--Carousel {
		.grid(1 span; 1 span);
		.box(block; 100%; auto; none);

		@media all and (min-width: @mobile-small) {
			&.ArrowAside {
				.grid(1 2; 1 2);
				.relative(2; hidden);
				.calc(width; ~'calc(100% - 10rem)');
			}
		}

		&--Item {
			.box(grid; 100%; 100%; none; 1rem; auto 1fr; auto; stretch; start);
			& > img {
				object-fit: cover;
				.grid-aspect(2.65; 2);
				.box(block; 100%; 100%);
				.border-radius(@BorderRadiusSmall);
			}
		}
	}

	&--CarouselNavigation {
		padding: 1rem 0;
		.box(grid; auto; auto; none; 2rem; 1fr; auto; center; center; column);

		&--Button {
			padding: 1rem !important;
			.grid(1 span);

			&.Disabled {
				border-color: transparent;
				background: fade(@ColorBase, 5%);
				.cursor(default);
				.box-shadow(0; 0; 0; 0; transparent) !important;
			}
		}

		@media all and (min-width: @mobile-small) {
			&.ArrowAside {
				padding: 0;
				.grid(1 2; 1 2);
				.relative(1);
				.box(grid; 100%; auto; none; 2rem; 1fr; auto; center; center; column);
				& > .SliderImages--CarouselNavigation--Button:first-child {
					.grid(1 span; 1 span; center; start);
				}
				& > .SliderImages--CarouselNavigation--Button:last-child {
					.grid(1 span; 1 span; center; end);
				}
			}
		}
	}

	@media all and (min-width: @laptop) {
		.relative(1; hidden);
	}
}
</style>
